import React, { StrictMode } from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "@mui/material"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import App from "./App"
import theme from "./theme"

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
      <StrictMode>
        <App />
      </StrictMode>
    </I18nextProvider>
  </ThemeProvider>,
  document.getElementById("root")
)

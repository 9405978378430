import { Typography } from "@mui/material"
import ButtonUnstyled, { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled"
import { styled } from "@mui/system"
import { withTranslation } from "react-i18next"
import "./App.css"
import background from "./assets/background.jpg"
import { useWindowWidth } from "./utilities"

const CustomButtonRoot = styled("button")`
  font-family: "Expletus Sans", cursive;
  font-size: 20px;
  background-color: transparent;
  padding: 16px;
  color: white;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 8px;

  &:hover {
    background-color: white;
    color: black;
    mix-blend-mode: screen;
  }

  &.${buttonUnstyledClasses.focusVisible} {
    background-color: white;
    color: black;
    mix-blend-mode: screen;
  }
`

const CustomButton = (props: {
  style: { [x: string]: any }
  onClick: Function
  [x: string]: any
}) => <ButtonUnstyled {...props} component={CustomButtonRoot} />

const App = withTranslation()(({ t }) => {
  const windowWidth: number = useWindowWidth()

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h1"
        style={{
          color: "white",
          fontSize: windowWidth >= 900 ? "11rem" : "5.5rem",
        }}
      >
        AGROO
      </Typography>
      <Typography
        variant="h1"
        style={{
          color: "white",
          fontSize: windowWidth >= 900 ? "3rem" : "1.5rem",
        }}
      >
        {t`Website under construction`}
      </Typography>
      <CustomButton
        style={{ position: "absolute", bottom: "48px" }}
        onClick={() => {
          window.location.href = "https://app.agroo.ooo"
        }}
      >{t`Looking for the app?`}</CustomButton>
    </div>
  )
})

export default App

import { useState, useEffect } from "react"

export const useWindowWidth = () => {
  const [windowWidth, setWindowSize] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize: () => void = () => {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowWidth
}
